<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="姓名"
          v-model="query.userRealName"
          @change="getList(1)"
        ></el-input>
      </el-col>
      
      <el-col :span="4">
        <el-input
          placeholder="手机号码"
          v-model="query.phone"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-select
          placeholder="是否允许提现"
          v-model="query.assetStatus"
          clearable
          @change="getList(1)"
        >
          <el-option
            v-for="(item, index) in this.const.USER_ALLOW_WITHDRAW"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      @sort-change="sortChange"
      row-class-name="table-row"
    >
      <el-table-column label="姓名" prop="userRealName">
        <template #default="scope">
          <span>{{ scope.row.userRealName ? scope.row.userRealName : "未实名" }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="手机号"
        prop="phone"
        align="right"
      ></el-table-column>

      <el-table-column
        label="总金额"
        align="right"
        prop="totalAmount"
        sortable="custom"
      >
        <template #default="scope">
          <span>{{ scope.row.totalAmount / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="可用余额"
        align="right"
        prop="balance"
        sortable="custom"
      >
        <template #default="scope">
          <span>{{ scope.row.balance / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="已使用金额"
        align="right"
        prop="usedAmount"
        sortable="custom"
      >
        <template #default="scope">
          <span>{{ scope.row.usedAmount / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="未使用金额"
        align="right"
        prop="unusedAmount"
        sortable="custom"
      >
        <template #default="scope">
          <span>{{ scope.row.unusedAmount / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="允许提现" prop="assetStatus">
        <template #default="scope">
          <eb-badge
            :list="this.const.USER_ALLOW_WITHDRAW"
            :target="scope.row.assetStatus"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span class="option option-primary" @click="doInviterDetail(scope.row)"
            >邀请记录</span
          >
          <span class="option option-primary" @click="doDetail(scope.row)"
            >详情</span
          >

          <span
            class="option option-primary"
            @click="showAssetsModal(scope.row)"
            >变更资产</span
          >

          <!-- <span
            class="option option-danger"
            v-if="
              scope.row.assetStatus ===
              this.const.USER_ALLOW_WITHDRAW_CODE.ALLOW
            "
            @click="
              updateWithdrawStatus(
                scope.row,
                this.const.USER_ALLOW_WITHDRAW_CODE.BAN
              )
            "
            >禁止提现</span>

          <span
            class="option option-primary"
            v-else
            @click="
              updateWithdrawStatus(
                scope.row,
                this.const.USER_ALLOW_WITHDRAW_CODE.ALLOW
              )
            "
            >允许提现</span
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <UpdateAssetsModal
      :modalData="modalData"
      :ref="MODAL_KEY.UPDATE_ASSETS_MODAL"
      @submit="doUpdateAssets"
    ></UpdateAssetsModal>
  </div>
</template>

<script>
import {
  fetchUserAssetList,
  updateUserAssets,
  updateAllowUserWithdraw,
} from "@/api/batteryMan";

import { mapState } from "vuex";
import UpdateAssetsModal from "./components/UpdateAssetsModal.vue";

export default {
  components: { UpdateAssetsModal },
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    return {
      
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      endTime: -1,
      expire: -1,
      query: {
        endTimeLt: "",
        endTimeGt: "",
      },
      modalType: "add",
      MODAL_KEY: {
        UPDATE_ASSETS_MODAL: "UPDATE_ASSETS_MODAL",
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },

  beforeRouteEnter(to, from, next) {
    if (from.fullPath.indexOf("detail") > -1) {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },

  activated() {
    if (!this.$route.meta.isBack || this.dataSource.length === 0) {
      this.resetPage();
      this.getList(1);
    }
  },

  methods: {
    updateWithdrawStatus(data, type) {
      updateAllowUserWithdraw({
        userId: data.userId,
        assetStatus: type,
      }).then((res) => {
        this.$message.success("操作成功！");
        this.getList();
      });
    },
    // 1总金额顺序 2总金额倒序 3未使用金额顺序 4未使用金额倒序 5可用余额顺序 6可用余额倒序 7已使用金额顺序 8已使用金额倒序
    // balance
    sortChange(e) {
      switch (e.prop) {
        case "totalAmount":
          this.query.sortType = e.order == "ascending" ? "1" : "2";
          break;
        case "usedAmount":
          this.query.sortType = e.order == "ascending" ? "7" : "8";
          break;
        case "balance":
          this.query.sortType = e.order == "ascending" ? "5" : "6";
          break;
        case "unusedAmount":
          this.query.sortType = e.order == "ascending" ? "3" : "4";
          break;
        default:
          this.query.sortType = "";
          break;
      }
      this.getList();
    },

    doUpdateAssets(data) {
      updateUserAssets(data).then((res) => {
        this.getList();
        this.$refs[this.MODAL_KEY.UPDATE_ASSETS_MODAL].closeModal();
      });
    },

    showAssetsModal(data) {
      this.modalData = {
        userId: data.userId,
        userRealName: data.userRealName || "未实名",
      };
      this.$refs[this.MODAL_KEY.UPDATE_ASSETS_MODAL].showModal();
    },

    resetPage() {
      this.pages = {
        pageIndex: 1,
        pageSize: 10,
      };

      this.query = {
        endTimeLt: "",
        endTimeGt: "",
      };
    },

    doDetail(data) {
      this.$router.push({
        path: "/user/assets/detail",
        query: { phone: data.phone },
      });
    },

    doInviterDetail(data) {
      this.$router.push({
        path: "/user/invite",
        query: { phone: data.phone },
      });
    },


    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchUserAssetList({ ...this.query, ...this.pages }).then((res) => {
        let list = res.data.data.records || [];
        this.total = res.data.data.total;
        this.dataSource = list;
      });
    },
  },
};
</script>
