<template>
  <el-dialog
    title="资产变更"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="用户">
        {{modalData.userRealName}}
      </el-form-item>

      <el-form-item label="金额" prop="amount">
        <el-input placeholder="输入金额" v-model="modalData.amount"></el-input>
      </el-form-item>

      <el-form-item label="变更类型" prop="adjustType">
        <el-radio-group v-model="modalData.adjustType">
          <el-radio label="5" size="large">增加</el-radio>
          <el-radio label="6" size="large">扣除</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          placeholder="请输入备注"
          v-model="modalData.remark"
          :autosize="{ minRows: 4, maxRows: 10 }"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { fetchCouponList } from "@/api/coupon";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      couponList: [],
      formName: "form",
      ruleValidate: {
        adjustType: [
          {
            required: true,
            message: "请选择增加资产还是扣除资产",
            trigger: "change",
          },
        ],
       
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
  
    submit() {
      let obj = this.deepClone(this.modalData);
      obj.amount = obj.amount * 100;
      this.validateForm().then((res) => {
        this.$emit("submit", obj);
      });
    },
  },

  mounted() {
  },
};
</script>
